export const REGISTER_USER_REQUEST="REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS="REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL="REGISTER_USER_FAIL";

export const USER_DETAIL_REQUEST = "USER_DETAIL_REQUEST";
export const USER_DETAIL_SUCCESS = "USER_DETAIL_SUCCESS";
export const USER_DETAIL_FAIL = "USER_DETAIL_FAIL";

export const GET_USER_INFO_REQUEST = "GET_USER_INFO_REQUEST";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_FAIL = "GET_USER_INFO_FAIL";

export const BUILDER_SETTINGS_REQUEST = "BUILDER_SETTINGS_REQUEST";
export const BUILDER_SETTINGS_SUCCESS = "BUILDER_SETTINGS_SUCCESS";
export const BUILDER_SETTINGS_FAIL = "BUILDER_SETTINGS_FAIL";

export const GET_BUILDER_ID_REQUEST = "GET_BUILDER_ID_REQUEST";
export const GET_BUILDER_ID_SUCCESS = "GET_BUILDER_ID_SUCCESS";
export const GET_BUILDER_RENTAL_SUCCESS = "GET_BUILDER_RENTAL_SUCCESS";
export const GET_BUILDER_ID_FAIL = "GET_BUILDER_ID_FAIL";

export const PHONE_NUMBER_REQUEST="PHONE_NUMBER_REQUEST";
export const PHONE_NUMBER_SUCCESS="PHONE_NUMBER_SUCCESS";
export const PHONE_NUMBER_FAIL="PHONE_NUMBER_FAIL";

export const PERSONA_DATA_REQUEST="PERSONA_DATA_REQUEST";
export const PERSONA_DATA_SUCCESS="PERSONA_DATA_SUCCESS";
export const PERSONA_DATA_FAIL="PERSONA_DATA_FAIL";

export const CHOOSE_COMMUNITY_REQUEST="CHOOSE_COMMUNITY_REQUEST";
export const CHOOSE_COMMUNITY_SUCCESS="CHOOSE_COMMUNITY_SUCCESS";
export const CHOOSE_COMMUNITY_FAIL="CHOOSE_COMMUNITY_FAIL";

export const SEARCH_COMMUNITY_REQUEST="SEARCH_COMMUNITY_REQUEST";
export const SEARCH_COMMUNITY_SUCCESS="SEARCH_COMMUNITY_SUCCESS";
export const SEARCH_COMMUNITY_FAIL="SEARCH_COMMUNITY_FAIL";

export const ADD_PROPERTY_ID="ADD_PROPERTY_ID";
export const GET_PROPERTY_REQUEST="GET_PROPERTY_REQUEST";
export const GET_PROPERTY_SUCCESS="GET_PROPERTY_SUCCESS";
export const GET_PROPERTY_FAIL = "GET_PROPERTY_FAIL";

export const GET_PROPERTY_ADDITIONAL_INFO_REQUEST = 'GET_PROPERTY_ADDITIONAL_INFO_REQUEST';
export const GET_PROPERTY_ADDITIONAL_INFO_SUCCESS = 'GET_PROPERTY_ADDITIONAL_INFO_SUCCESS';
export const GET_PROPERTY_ADDITIONAL_INFO_FAIL = 'GET_PROPERTY_ADDITIONAL_INFO_FAIL';

export const GET_SURVEY_QUESTION_REQUEST = 'GET_SURVEY_QUESTION_REQUEST'
export const GET_SURVEY_QUESTION_SUCCESS = 'GET_SURVEY_QUESTION_SUCCESS'
export const GET_SURVEY_QUESTION_FAIL = 'GET_SURVEY_QUESTION_FAIL'

export const TOUR_DATE_REQUEST="TOUR_DATE_REQUEST";
export const TOUR_DATE_SUCCESS="TOUR_DATE_SUCCESS";
export const TOUR_DATE_FAIL="TOUR_DATE_FAIL";

export const ADD_SCHEDULED_VISIT_ID='ADD_SCHEDULED_VISIT_ID';
export const SCHEDULED_VISITS_REQUEST='SCHEDULED_VISITS_REQUEST';
export const SCHEDULED_VISITS_CANCEL='SCHEDULED_VISITS_CANCEL';
export const SCHEDULED_VISITS_SUCCESS='SCHEDULED_VISITS_SUCCESS';
export const SCHEDULED_VISITS_FAIL='SCHEDULED_VISITS_FAIL';

export const GET_SCHEDULED_TOURS_REQUEST='GET_SCHEDULED_TOURS_REQUEST';
export const GET_SCHEDULED_TOURS_SUCCESS='GET_SCHEDULED_TOURS_SUCCESS';
export const GET_SCHEDULED_TOURS_FAIL='GET_SCHEDULED_TOURS_FAIL';

export const GET_COMPLETED_TOURS_REQUEST='GET_COMPLETED_TOURS_REQUEST';
export const GET_COMPLETED_TOURS_SUCCESS='GET_COMPLETED_TOURS_SUCCESS';
export const GET_COMPLETED_TOURS_FAIL='GET_COMPLETED_TOURS_FAIL';

export const AVAILABLE_TOUR_HOURS_REQUEST='AVAILABLE_TOUR_HOURS_REQUEST';
export const AVAILABLE_TOUR_HOURS_SUCCESS='AVAILABLE_TOUR_HOURS_SUCCESS';
export const AVAILABLE_TOUR_HOURS_FAIL='AVAILABLE_TOUR_HOURS_FAIL';

export const ADD_STATIC_TOKEN_REQUEST='ADD_STATIC_TOKEN_REQUEST';
export const ADD_STATIC_TOKEN_SUCCESS='ADD_STATIC_TOKEN_SUCCESS';
export const ADD_STATIC_TOKEN_FAIL='ADD_STATIC_TOKEN_FAIL';

export const ADD_ATTRIBUTION = 'ADD_ATTRIBUTION';